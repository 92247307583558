import {
  StyledCategoryTab,
  LabelSpan,
  C,
} from 'CORE__UI/navigation/EventsCategoryTab/core__events-category-tab-styles';
import styled from 'styled-components';
import { GREYS, BRAND, BK_COLORS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const StyledCategoryTab_QN = styled(StyledCategoryTab)`
  color: ${GREYS.black};
  background-color: ${GREYS.white};
  margin-right: 8px;
  padding: 0 16px 0 12px;
  border: none;
  border-radius: 4px;

  box-shadow: none;
  @media (hover) {
    &:hover {
      color: ${GREYS.black};
      font-weight: 800;
      box-shadow: none;
    }
  }
  @media (max-width: ${BREAKPOINT_S}) {
    flex-direction: column;
    height: 60px;
    min-width: 60px;
    font-size: 10px;
    letter-spacing: 0.35px;
    text-align: center;
    padding: 0 4px;
    i {
      font-size: 22px;
      margin-bottom: 6px;
      margin-right: 0;
    }
  }
`;
const C_QN = styled(C)`
  background-color: ${({ active }) =>
    active
      ? BK_COLORS?.red || BRAND.primary
      : BK_COLORS?.EVENTS.categoryShadow || GREYS.doveD};
  color: ${({ active }) => (active ? FONT.white.label : GREYS.black)};
  font-weight: 400;
`;
export { StyledCategoryTab_QN as StyledCategoryTab, C_QN as C, LabelSpan };
