import { ED, OSDW, Live } from 'CORE__UI/apps/EventsApp/core__eventDate';
import styled from 'styled-components';
import { FONT } from '../../globals/colours';
const ED_EXT = styled(ED)`
  color: ${FONT.black.mid};
  &:first-child {
    margin-left: 0;
  }
  @media (max-width: 420px) {
    font-size: 11px;
  }
`;
export { ED_EXT as ED, OSDW, Live };
