import styled from 'styled-components';
import {
  SW,
  Label,
  Info,
  NS,
  BD,
} from 'CORE__UI/globals/Select/core__selectStyles';
import { FONT, FUNCTIONAL } from 'UI/globals/colours';

const Label_EX = styled(Label)`
  color: ${FONT.black.standard};
`;

const Info_EX = styled(Info)`
  color: ${FUNCTIONAL.error};
`;

const SW_EX = styled(SW)`
  position: relative;
`;

export { SW_EX as SW, Label_EX as Label, Info_EX as Info, NS, BD };
