import React from 'react';
import styled from 'styled-components';
import { FONT, BK_COLORS } from '../../globals/colours';
import { BREAKPOINT_S } from '../../globals/breakpoints';
import {
  EventName,
  EventDate,
  EventMarket,
  EventsRow,
  MoreMarketsRow,
  RightArrow,
  StyledAnchor,
  EmptyAmericanMarket,
} from 'CORE__UI/apps/EventsApp/core__eventsTableRow';

const StyledAnchorArrow = styled(StyledAnchor)`
  color: ${FONT.black.mid};
  font-weight: 400;
  & i {
    margin-left: 4px;
  }
  @media (max-width: 420px) {
    font-size: 11px;
  }
`;

const MoreMarketsRow_Q = styled(MoreMarketsRow)`
  border-color: ${BK_COLORS.aliceBlue10};
  padding-left: 16px;
  padding-right: 16px;
  padding-top: 8px;
  padding-bottom: 12px;
  line-height: 16px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-top: 0;
    padding-bottom: 8px;
  }
  @media (max-width: 420px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

const EventsRow_Q = styled(EventsRow)`
  padding-left: 10px;
  padding-right: 16px;
  padding-top: 16px;
  @media (max-width: ${BREAKPOINT_S}) {
    padding-top: 8px;
  }
  @media (max-width: 420px) {
    padding-left: 6px;
    padding-right: 12px;
  }
`;

const RightArrow_Q = styled(RightArrow)`
  border-width: 0 1px 1px 0;
  border-color: ${FONT.black.mid};
`;

export {
  EventName,
  EventDate,
  EventMarket,
  EventsRow_Q as EventsRow,
  MoreMarketsRow_Q as MoreMarketsRow,
  RightArrow_Q as RightArrow,
  StyledAnchor,
  EmptyAmericanMarket,
  StyledAnchorArrow,
};
export const EventFixture = React.Fragment;
