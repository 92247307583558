import { EventsTable } from 'CORE__UI/apps/EventsApp/core__eventsTable';
import styled from 'styled-components';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const EventsTable_Q = styled(EventsTable)`
  box-shadow: none;
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
`;
export { EventsTable_Q as EventsTable };
