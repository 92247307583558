import styled from 'styled-components';
import { SC } from 'CORE__UI/apps/EventsApp/core__categoryTabs';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const SC_EX = styled(SC)`
  height: 100%;
  margin-bottom: 0;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-left: 0;
    padding-top: 0;
  }
`;

export { SC_EX as SC };
