import React from 'react';
import { EventDate } from './core__eventDate';
import { Market } from './core__market';
import { Teams } from './core__teams';
import { EventsTableRow } from '../core__eventsTableRow';

const Market_Copy = props => {
  return <Market {...props} showFractionalOdds={true} />;
};
export { EventDate, Market_Copy as Market, Teams, EventsTableRow };
