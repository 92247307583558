import {
  getCatSubcatFromUrl,
  getSupportedMarketsNew,
  parseAppConfig,
  shouldInvokeOutrights,
} from './core__events-utils';
import { getToFromTodayWithOffset } from 'Services/datetime/core__date-and-time';
import { SH_MARKETS } from './core__events-constants';
import { GLOBAL_PATH } from 'Services/global/core__constants';
import { GLOBAL_CONSTANTS } from 'Services/global/core__constants';

const getUrlParamMarketTypesNew = (
  markets,
  options,
  appConfig,
  catreftoload
) => {
  // This if block added for gamesys to show default market as AH
  if (
    typeof markets !== 'undefined' &&
    markets.length > 0 &&
    appConfig &&
    appConfig.defaultmarket &&
    (catreftoload === GLOBAL_CONSTANTS.SOCCER ||
      catreftoload.indexOf(GLOBAL_CONSTANTS.SOCCER) > -1)
  ) {
    markets = [...new Set(markets)];
    const defMarketFromCms = appConfig.defaultmarket.split(':');
    const getMarkets = defMarketFromCms[1];
    markets.unshift(getMarkets);
    return 'metadata=true&detail=' + markets.join(',');
  } else if (typeof markets !== 'undefined' && markets.length > 0) {
    markets = [...new Set(markets)];
    return '&detail=' + markets.join(',');
  }
};

//to get best row in SH markets
const getUrlParamSH = (markets, appConfig, catreftoload) => {
  if (
    typeof markets !== 'undefined' &&
    markets.length > 0 &&
    appConfig &&
    appConfig.defaultmarket &&
    (catreftoload === GLOBAL_CONSTANTS.SOCCER ||
      catreftoload.indexOf(GLOBAL_CONSTANTS.SOCCER) > -1)
  ) {
    markets = [...new Set(markets)];
    const defMarketFromCms = appConfig.defaultmarket.split(':');
    const getMarkets = defMarketFromCms[1];
    markets.unshift(getMarkets);
  } // This if block added for gamesys to show default market as AH
  if (typeof markets !== 'undefined' && markets.length > 0) {
    const shMarkets = [];
    markets.forEach(marketRef => {
      marketRef.split(',').forEach(mr => {
        //as some markets are grouped
        if (SH_MARKETS.includes(mr) && !shMarkets.includes(mr)) {
          shMarkets.push(mr);
        }
      });
    });
    if (shMarkets.length > 0) {
      //removed temporarily since backened isn ot JNREACT-1148
      return '&singleHandicapMarket=' + shMarkets.join(',');
    } else {
      return '';
    }
  }
};

//URL param parsing
const getUrlParam = ({ name, value }) => {
  return (
    (value &&
      typeof value !== 'undefined' &&
      value !== '' &&
      `&${name}=${value}`) ||
    ''
  );
};

const getUrlEventCount = (appConfig, subcat) => {
  const {
    catreftoload,
    state,
    marketTypes,
    americanLayout,
    columns,
    disabledropdown,
    subcatalbumref,
  } = parseAppConfig(appConfig);

  const scheduledTo =
    appConfig.offset && getToFromTodayWithOffset(appConfig.offset);
  const scheduledFrom =
    appConfig.scheduledfrom &&
    getToFromTodayWithOffset(appConfig.scheduledfrom);

  const base = GLOBAL_PATH.EVENT_COUNT_BASE;

  const { markets } = getSupportedMarketsNew(
    marketTypes,
    catreftoload,
    americanLayout,
    columns,
    null,
    null,
    disabledropdown
  );

  const urlState = getUrlParam({ name: 'state', value: state });
  const urlMarketTypes = getUrlParamMarketTypesNew(markets);
  const urlScheduledTo = getUrlParam({
    name: 'scheduledTo',
    value: scheduledTo,
  });
  const urlScheduledFrom = getUrlParam({
    name: 'scheduledFrom',
    value: scheduledFrom,
  });
  const urlAlbumSubcat = getUrlParam({
    name: 'subcatAlbumRef',
    value: subcatalbumref,
  });
  const subcats = subcat ? 'true' : 'false';
  const countUrl = `${base}?subcats=${subcats}&${urlMarketTypes}${urlState}${urlScheduledTo}${urlScheduledFrom}${urlAlbumSubcat}`;
  return countUrl;
};
export const getUrlEventData = (appConfig, options, cookies) => {
  if (appConfig.cookies) {
    cookies = appConfig.cookies;
  }
  let {
    catreftoload,
    featured,
    inrunning,
    state,
    subcatreftoload,
    limit,
    requestURL,
    marketTypes,
    americanLayout,
    columns,
    disabledropdown,
    subcatalbumref,
    marketalbumref,
    permissivealbum,
    singleHandicapFromBE,
    subcatidtoload,
  } = parseAppConfig(appConfig);
  if (options && options.catreftoload) {
    catreftoload = options.catreftoload;
  }
  const scheduledTo =
    appConfig.offset && getToFromTodayWithOffset(appConfig.offset);
  const scheduledFrom =
    appConfig.scheduledfrom &&
    getToFromTodayWithOffset(appConfig.scheduledfrom);

  let base = GLOBAL_PATH.EVENT_DATA_BASE;
  if (requestURL && (!catreftoload || !subcatreftoload)) {
    const catSubcat = getCatSubcatFromUrl(requestURL, appConfig?.urlamendments);
    if (!catreftoload) catreftoload = catSubcat.catreftoload;
    if (!subcatreftoload) subcatreftoload = catSubcat.subcatreftoload;
    if (!subcatidtoload && !isNaN(catSubcat.subcatidtoload))
      subcatidtoload = catSubcat.subcatidtoload;
  }

  if (requestURL && !subcatidtoload) {
    const catSubcat = getCatSubcatFromUrl(requestURL, appConfig?.urlamendments);
    if (!subcatidtoload && !isNaN(catSubcat.subcatidtoload))
      subcatidtoload = catSubcat.subcatidtoload;
  }

  const { categories, markets } = getSupportedMarketsNew(
    marketTypes,
    catreftoload,
    americanLayout,
    columns,
    options,
    cookies,
    disabledropdown
  );

  if (categories) {
    base += `/${categories.join(',')}`;
  }

  //subcatref is fixed if loaded from appconfig or url
  if (subcatidtoload) base += `/id/${subcatidtoload}`;
  else if (subcatreftoload) base += `/${subcatreftoload}`;
  const urlMarketTypes = getUrlParamMarketTypesNew(
    markets,
    options,
    appConfig,
    catreftoload
  );
  const urlAlbumSubcat = getUrlParam({
    name: 'subcatAlbumRef',
    value: subcatalbumref,
  });
  let urlAlbumMarket = getUrlParam({
    name: 'marketAlbumRef',
    value: marketalbumref,
  });
  let permissiveAlbum = getUrlParam({
    name: 'permissiveAlbum',
    value: permissivealbum,
  });
  if (!urlAlbumMarket && !urlAlbumSubcat) {
    permissiveAlbum = '';
  }
  const urlInRunning = getUrlParam({ name: 'inRunning', value: inrunning });
  const urlState = getUrlParam({ name: 'state', value: state });
  const urlFeatured = getUrlParam({ name: 'featured', value: featured });
  const urlScheduledTo = getUrlParam({
    name: 'scheduledTo',
    value: scheduledTo,
  });
  const urlScheduledFrom = getUrlParam({
    name: 'scheduledFrom',
    value: scheduledFrom,
  });
  let urlSHMarket =
    (!singleHandicapFromBE &&
      getUrlParamSH(markets, appConfig, catreftoload)) ||
    '';

  if (appConfig.useshmarket) {
    urlSHMarket =
      '&singleHandicapMarket=OU,TGOU,SHC,SHC_LS,MTGOU,RMLOU,EAH,OPAR,TPM,MTL_OU,SHC_NT,HRML,SHC_S,AH';
  }

  const urlLimitEvents = (+limit && `&limit=${limit}`) || '';
  if (urlAlbumMarket) {
    urlAlbumMarket += '&allDetail=true';
  }
  return `${base}.fsb?${urlMarketTypes}${urlState}${urlFeatured}${urlScheduledTo}${urlInRunning}${urlLimitEvents}${urlSHMarket}${urlAlbumSubcat}${urlAlbumMarket}${permissiveAlbum}${urlScheduledFrom}`;
};
export const fetchEventsCount = (appConfig, requester, subcat) => {
  let url = getUrlEventCount(appConfig, subcat);
  if (appConfig.fromSSR) {
    url = url.replace('.fsb', '.json');
  }
  return requester(url)
    .then(async data => {
      //if offset removed
      if (
        appConfig.state === GLOBAL_CONSTANTS.SCHEDULED &&
        !data.category &&
        appConfig.offset
      ) {
        //date is not fetched due to offset, do another call without offset
        data = await getCountWithoutOffset(appConfig, requester);
      }

      //if not in virtual page, filter out virtual only events
      if (!appConfig.onVirtual) {
        data.category = data.category.filter(category => {
          return category.eventCount > 0 || category.esportEventCount > 0;
        });
      }
      //if subcat is present
      if (subcat && data.category) {
        data.category = data.category.filter(category => {
          //mutable object, intentionaly done
          category.eventCount = 0;

          let found = false;
          category.subcat = category.subcat?.filter(sub => {
            // In case of urlAmendments 1st condition may fail due to [_-] pattern in the subcatRef then 2nd condition will take care of it
            if (
              subcat.indexOf(sub.ref) !== -1 ||
              subcat.replace(/[-_]/g, '').indexOf(sub.ref.replace(/[-_]/g, ''))
            ) {
              found = true;
              category.eventCount += sub.eventCount;
              return true;
            }
          });
          return found;
        });
      }
      return data;
    })
    .catch(e => {
      return e;
    });
};
export const fetchEventsData = (appConfig, requester, catRef) => {
  //invoke outrights
  const invokeOutrights = shouldInvokeOutrights(
    appConfig,
    catRef || appConfig.catreftoload
  );
  if (invokeOutrights) {
    return Promise.resolve();
  }

  let url = getUrlEventData(appConfig, { catreftoload: catRef });

  if (appConfig.fromSSR) {
    url = url.replace('.fsb', '.json');
  }
  return requester(url)
    .then(async data => {
      if (
        appConfig.state === GLOBAL_CONSTANTS.SCHEDULED &&
        !data.category &&
        appConfig.offset
      ) {
        //date is not fetched due to offset, do another call without offset
        data = await getDataWithoutOffset(appConfig, requester);
      }
      return data;
    })
    .catch(e => {
      return e;
    });
};

const getDataWithoutOffset = async (appConfig, requester, catRef) => {
  let url = getUrlEventData(
    { ...appConfig, offset: null },
    { catreftoload: catRef }
  );
  if (appConfig.fromSSR) {
    url = url.replace('.fsb', '.json');
  }
  return await requester(url);
};

const getCountWithoutOffset = async (appConfig, requester) => {
  let url = getUrlEventCount({ ...appConfig, offset: null });
  if (appConfig.fromSSR) {
    url = url.replace('.fsb', '.json');
  }
  return await requester(url);
};
