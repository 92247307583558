import {
  StyledMarketName,
  SMN,
  StyledCatName,
  StyledRow,
} from 'CORE__UI/apps/EventsApp/core__eventsTableHeader';

import styled from 'styled-components';
import { BRAND, GREYS, FONT } from 'UI/globals/colours';
import { BREAKPOINT_S } from 'UI/globals/breakpoints';

const StyledRow_EXT = styled(StyledRow)`
  background-color: ${BRAND.primary};
  min-height: 48px;
  padding: 12px 16px;
  line-height: 20px;
  @media (max-width: ${BREAKPOINT_S}) {
    border-radius: 0;
  }
  @media (max-width: 420px) {
    min-height: 40px;
    padding: 12px;
    line-height: 16px;
    font-size: 13px;
  }
`;

const StyledCatName_EXT = styled(StyledCatName)`
  color: ${GREYS.white};
`;

const StyledMarketName_EXT = styled(StyledMarketName)`
  color: ${FONT.white.standard};
`;

export {
  StyledMarketName_EXT as StyledMarketName,
  SMN,
  StyledCatName_EXT as StyledCatName,
  StyledRow_EXT as StyledRow,
};
