import { TeamName, Team, TeamScore } from 'CORE__UI/apps/EventsApp/core__teams';
import styled from 'styled-components';
import { BREAKPOINT_S } from '../../globals/breakpoints';
import { BK_COLORS } from 'UI/globals/colours';
const TeamName_Q = styled(TeamName)`
  border-color: ${BK_COLORS.red};
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 0;
    margin-bottom: 4px;
  }
`;
const TeamScore_Q = styled(TeamScore)`
  color: ${BK_COLORS.red};
  font-weight: 800;
  @media (max-width: ${BREAKPOINT_S}) {
    margin-top: 0;
    margin-bottom: 4px;
  }
`;
const Team_Q = styled(Team)`
  @media (max-width: 420px) {
    font-size: 13px;
    line-height: 16px;
  }
`;
export { TeamName_Q as TeamName, Team_Q as Team, TeamScore_Q as TeamScore };
