import { StyledSelect } from 'CORE__UI/lines/dropdowns/MarketSelect/core__marketSelectStyles';
import styled from 'styled-components';
import { GREYS } from '../globals/colours';

const StyledSelect_EXT = styled(StyledSelect)`
  div[class*='singleValue'] {
    color: ${GREYS.white};
  }
`;
export { StyledSelect_EXT as StyledSelect };
